import React from "react";
import { Stack } from "@mui/material";
import TabLineSection from "./parts/TabLineSection";
import ContactForm from "./parts/ContactForm";
import Footer from "../../utils/Footer";
import GoToTop from "../../utils/GoToTop";
import { Helmet } from "react-helmet";
const GetInTouch = () => {
  return (
    <Stack width={"100%"} height={"100%"} bgcolor={"#F5F7FF"}>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-11321518946"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'AW-11321518946');
          `}
        </script>
      </Helmet>
      <TabLineSection />
      <ContactForm />
      <Footer />
      <GoToTop />
    </Stack>
  );
};

export default GetInTouch;
